@import url("//hello.myfonts.net/count/3d5c90");

@font-face {
  font-family: "SofiaPro-Regular";
  src: url("../sofiapro/SofiaProRegular/font.woff2") format("woff2"),
    url("../sofiapro/SofiaProRegular/font.woff") format("woff");
}

@font-face {
  font-family: "SofiaPro-Bold";
  src: url("../sofiapro/SofiaProBold/font.woff2") format("woff2"),
    url("../sofiapro/SofiaProBold/font.woff") format("woff");
}
@font-face {
  font-family: "SofiaPro-Light";
  src: url("../sofiapro/SofiaProLight/font.woff2") format("woff2"),
    url("../sofiapro/SofiaProLight/font.woff") format("woff");
}

body {
  font-family: "SofiaPro-Regular", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.b3 {
  font-family: "SofiaPro-Regular", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
}

.b2 {
  font-family: "SofiaPro-Regular", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.b1,
p {
  font-family: "SofiaPro-Regular", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

.regular {
  font-family: "SofiaPro-Regular", sans-serif;
  font-weight: 400;
}

.light {
  font-family: "SofiaPro-Light", sans-serif;
  font-weight: 300;
}

.bold {
  font-family: "SofiaPro-Bold", sans-serif;
  font-weight: 700;
}

.h1,
h1 {
  font-family: "SofiaPro-Bold", sans-serif;
  font-size: 2.25rem;
  line-height: 2.75rem;
  font-weight: 700;
}

.h2,
h2 {
  font-family: "SofiaPro-Bold", sans-serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 700;
}

.h3,
h3 {
  font-family: "SofiaPro-Regular", sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
p,
a,
button,
input,
select,
textarea {
  font-kerning: normal !important;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  /* font-smoothing: antialiased; */
}
