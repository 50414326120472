.m0 {
  margin: 0;
}
.m1 {
  margin: 1rem;
}
.m2 {
  margin: 2rem;
}
.mtop0 {
  margin-top: 0;
}
.mbottom0 {
  margin-bottom: 0;
}
.mtop1 {
  margin-top: 1rem;
}
.mtop05 {
  margin-top: 0.5rem;
}
.mbottom1 {
  margin-bottom: 1rem;
}
.mbottom05 {
  margin-bottom: 0.5rem;
}
.mleft1 {
  margin-left: 1rem;
}
.mright1 {
  margin-right: 1rem;
}
.mtop2 {
  margin-top: 2rem;
}
.mbottom2 {
  margin-bottom: 2rem;
}
.p0 {
  padding: 0;
}
.p1 {
  padding: 1rem;
}
.p2 {
  padding: 2rem;
}
.ptop0 {
  padding-top: 0;
}
.pbottom0 {
  padding-bottom: 0;
}
.ptop1 {
  padding-top: 1rem;
}
.pbottom1 {
  padding-bottom: 1rem;
}
.pleft1 {
  padding-left: 1rem;
}
.ptop2 {
  padding-top: 2rem;
}
.pbottom2 {
  padding-bottom: 2rem;
}
.pleft2 {
  padding-left: 2rem;
}
.center {
  text-align: center;
}
.b {
  font-weight: 700;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.gap1 {
  gap: 1rem;
}
.gap05 {
  gap: 0.5rem;
}

.two-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid {
  display: grid;
}

.ac {
  align-items: center;
}

.jc {
  justify-content: center;
}

.jce {
  justify-content: flex-end;
}

.jcfe {
  justify-content: flex-end;
}

.jcfs {
  justify-content: flex-start;
}
.jcsb {
  justify-content: space-between;
}

.aife {
  align-items: flex-end;
}

.asfe {
  align-self: flex-end;
}

.jsfe {
  justify-self: flex-end;
}
.fdc {
  flex-direction: column;
}
.c {
  text-align: center;
}
.pointer {
  cursor: pointer;
}
