:root {
  --toastify-color-error: #a82525;
  --toastify-color-success: #21725e;
  --toastify-color-info: #f7e214;
}
*,
*:before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

button,
input,
textarea,
select {
  font-family: inherit;
}

a,
button {
  color: black;
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
}

img.site-banner {
  width: revert;
}

/* .container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3rem;
  padding-right: 3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
}

.container-fw {
  padding-left: 3rem;
  padding-right: 3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1rem;
} */

.ac {
  align-items: center;
}

.jse {
  justify-self: flex-end;
}

.g1 {
  gap: 1rem;
}
